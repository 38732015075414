.login_section{
    min-height: calc(100vh - 56px);
}
.login_header{
    box-shadow: 0 1px 0 0 $grey;
    padding: 12px 0px;

    img{
        max-width: 40px;
        max-height: 32px;
    }

    h3{
        font-size: 21px;
        color:  $dark_text;
        font-weight: normal;
    }
}
.login_box{
    width: 452px;
    padding: 30px 30px;

    @include media-breakpoint-down(sm){
        width: 95%;
    }

    h3{
        font-size: 21px;
        color:  $dark_text;
        font-weight: normal;
    }

    a {
        border: 1px solid $grey;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0px;
        color: $dark_text;
        font-size: 18px;
        &:focus,
        &:hover{
        border-color:$light;
        box-shadow:none;
        }

        span{
            width: 28px;
            height: 28px;
            background-color: #F3F3F3;
            border-radius: 50%;
            padding: 5px;
            margin-right: 13px;
            overflow: hidden;
            line-height: 17px;
        }
    }

    p{
        font-size: 15px;
        color: $dark_grey;
        font-weight: normal;

        &:after,
        &:before{
            content: '';
            width: 81px;
            height: 1px;
            position: absolute;
            background-color: $light_text;
            top: 50%;

            @include media-breakpoint-down(sm){
                content: none;
            }
        }

        &:before{
            left: 0;
        }

        &:after{
            right: 0;
        }
    }
}

.login_box .form-group label{
    font-size: 15px;
    color: $dark_grey;
}
.forgot_pswd a{
    color: $dark_grey;
    padding: 0;
    border: none;
    text-decoration: underline;
    font-size: 12px;
    justify-content: left;
}
