.w-30{
    width: 30%;
}
.w-70{
    width: 70%;
}
.height-35{
    height: 39px !important;
}
.height-40{
    height: 40px !important;
}
.height-44{
    height: 44px !important;
}
.height-50{
    height: 50px !important;
}
.px-6{
    padding-left: 6px !important;
    padding-right: 6px !important;
}
.p-20{
    padding: 20px !important;
}
.pl-20{
    padding-left: 20px !important;
}
.py-20{
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.mt-94{
    margin-top: 94px;
}
.mt-105{
    margin-top: 105px;

    @include media-breakpoint-down(md){
        margin-top: 0px;
    }
}
.mb-12{
    margin-bottom: 12px;
}
.mb-20{
    margin-bottom: 20px;
}
.mr-30{
    margin-right: 30px;
}
.b-shadow-4{
    box-shadow: 0 0 4px 0 #e8eef3;
}
.b-r-8{
    border-radius: 8px !important;
}
.d-grid{
    display: grid;
}

@include media-breakpoint-down(md){
    .mt-md-94{
        margin-top: 94px;
    }
}

.highcharts-figure,
.highcharts-data-table table {
    min-width: 310px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}



