/*********************************** MAIN CONTENT *********************************/
.content-wrapper{
  padding: 20px 28px;

  @include media-breakpoint-down(md){
    padding: 0px 25px 44px 25px;
  }
}
// .main-container.in{
//   z-index: auto;
// }
.main-container {
  margin-left: 240px;
  min-height: calc(100vh - 63px);
  position: relative;
  transition: width .3s ease-in-out, margin-left .3s ease-in-out;
  // z-index: 0;

  @include media-breakpoint-down(md){
    margin-left: 0px;
  }
}
.page-title {
    padding: 10px 28px;
    transition: width .3s ease-in-out;

    @include media-breakpoint-down(md){
      padding: 24px 24px;
  }
}

.taskExportBtns button{
  padding: 9px 11px;
}

@include media-breakpoint-down(sm){
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
      justify-content: start !important;
  }
}

.task-detail-panel{
    background-color: #fff;
    position: fixed;
    top: 0px;
    right: -320px;
    width: 240px;
    z-index: 3;
    transition: all .5s ease;
    border: solid 1px #e8eef3;
    // white-space: nowrap;
    // overflow-x: hidden;
    height: 100vh;
}

.task-detail-panel.in{
    transition: all .5s ease;
    width: 63%;
    right: 0px;

    @include media-breakpoint-down(sm){
      width: 100%;

      .w-25 {
          width: 40% !important;
      }
    }
}

.close-task-detail{
    position: absolute;
    left: -55px;
    top: 17px;
    background-color: #1D82F5;
    padding: 9px 17px !important;
    color: #fff;
    border-radius: 22px 0px 0px 22px;
    font-size: 12px;
    transition: width 0.7s;
    opacity: 0;


    @include media-breakpoint-down(sm){
        left: auto;
        right: 0;
        background-color: transparent;
        position: fixed;
        z-index: -1;
    }

    span{
          width: 20px;
          height: 20px;
          border: 1px solid #1D82F5;
          border-radius: 100%;
          position: relative;
          display: block;
          transition: all 0.5s ease;

          @include media-breakpoint-down(sm){
              border: 1px solid #28313c;
          }

          .fa-times{
              position: absolute;
              left: 0;
              right: 0;
              margin: 0 auto;
              text-align: center;
              top: 3px;
              color: white;

              // @include media-breakpoint-down(sm){
              //     color: #28313c;
              // }
        }
     }

    &:hover{
      span{
          border: 1px solid #fff;
          transition: all 0.5s ease;
      }
    }
}

.close-task-detail.in{
    opacity: 1;
    transition: width 0.7s;
    z-index: 1;
    display: block !important;
}

@include media-breakpoint-down(sm){
    .task-detail-panel.in .close-task-detail.in {
        opacity: 1;
        transition: width 0.7s;
        display: block !important;
    }

    .task-detail-panel .close-task-detail {
        opacity: 0;
        transition: width 0.7s;
    }
}


.t-d-inner-panel{
    height: 100%;
    overflow: auto;
}

.task-overlay{
    position: fixed;
    top: 0;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 3;
    display: none;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
}

.task-overlay.in{
    display: block !important;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
}

@media screen
and (max-device-width: 1172px)
{

  .task-detail-panel.in{
    width:120vw !important;
  }
}


@media screen
and (min-device-width: 1173px)
and (max-device-width: 1270px)
{

  .task-detail-panel.in{
    width:93vw !important;
  }
}



@media screen
and (min-device-width: 1270px)
and (max-device-width: 1316px)
{

  .task-detail-panel.in{
    width:88vw !important;
  }
}



@media screen
and (min-device-width: 1316px)
and (max-device-width: 1400px)
{

  .task-detail-panel.in{
    width:85vw !important;
  }
}


@media screen
and (min-device-width: 1400px)
and (max-device-width: 1500px)
{

  .task-detail-panel.in{
    width:80vw !important;
  }
}

@media screen
and (min-device-width: 1500px)
and (max-device-width: 1603px)
{

  .task-detail-panel.in{
    width:75vw !important;
  }
}

@media screen
and (min-device-width: 1604px)
and (max-device-width: 1699px)
{

  .task-detail-panel.in{
    width:67vw !important;
  }
}

@media screen
and (min-device-width: 1700px)
{

  .task-detail-panel.in{
    width:65vw !important;
  }
}
