.c-inv-desc {

    table tr td{
       border: 1px solid $amt_grey;
       padding: 11px 10px;
    }
}

.c-inv-desc-table .item_name, .c-inv-desc-table .quantity, .c-inv-desc-table .cost_per_item, .hsn_sac_code {
    padding: 0.5rem !important;
    border: 1px solid $amt_grey !important;
    border-radius: 0.25rem !important;
}

@include media-breakpoint-down(sm){
        .c-inv-desc-table{

            table{
                tr{
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    display: block;
                    width: 50%;
                    float: left;

                    .inv-desc-mbl{
                        height: 92px;
                    }
                }

                td{
                    display: block;
                    -ms-flex: 1 1 auto;
                    flex: 1 1 auto;
                    border: 1px solid #e7e9eb !important;
                    width: 100%;
                    height: 46px;
                }
            }

            a{
                justify-content: flex-end!important;
                margin-top: 10px;
            }

            .select-others{
                line-height: 0;
                font-weight: 400;
            }
        }
    }
.c-inv-amt{
    p{
        height: 70px;
        display: block;
        line-height: 2.7;
        text-align: right;
    }
}
.c-inv-close{
    height: 96px;
}
.c-inv-total{
    table tr td{
       border: 1px solid $grey;
       padding: 11px 10px;

        .c-inv-sub-padding{
           padding: 5px 10px;

           @include media-breakpoint-down(sm){
               padding: 5px 0px;
           }
        }
    }
}
.c-inv-note-terms{
    textarea{
        width: 96%;

        @include media-breakpoint-down(sm){
            width: 100%;
        }
    }
}
.c-inv-btns {
    .dropdown-toggle {
        font-size: 15px;
        color: $white;
        border: solid 1px $dark_grey;
        padding: 0px 9px;
        border-radius: 4px;
        height: 36px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 0;

        span {
            border-left: 1px solid $white;
            height: 34px;
            display: inline-flex;
            padding-left: 8px;
            align-items: center;
            margin-left: 8px;

            @include media-breakpoint-down(sm){
                margin-left: 28px;
            }
        }
    }
}
.dark_place input::placeholder, .c-inv-date-pickerinput::placeholder{
    color: $dark_text !important;
    font-weight: normal important;
}
.dash-border-top{
    border-top: 1px dashed #c3c3c3 !important;
}

.c-inv-btns{
    display: flex;
    flex-flow: row;
    justify-content: flex-end;

    @include media-breakpoint-down(sm){
        // flex-flow: column;

        .btn-secondary, .inv-action{
            width: auto;
        }

        .inv-action button{
            width: 100%;
        }

        .btn-cancel{
            order: 3;
        }
    }
}

.btlr {
    border-top-left-radius: 4px;
}

.btrr-bbrr {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    @include media-breakpoint-down(sm){
        border-top-right-radius: 0px;
    }
}

.bblr {
    border-bottom-left-radius: 4px;
}

@include media-breakpoint-down(sm){
    .btrr-mbl{
        border-top-right-radius: 4px;
        border-top-left-radius: 0px;
    }

    .bblr-mbl{
        border-bottom-left-radius: 4px;
    }
}

table {
    border-collapse: separate !important;
    border-spacing: inherit;
}
