$helvetica: 'Nunito Sans', sans-serif;

$c-black: #18181a !default;

$lightGrey: #F3F3F3;

$dark_bg: #171f29 !important;

$white_shade: #f7faff;

$dark_border: #252d37;

/************************ DASHBOARD UI KIT ***********************/

$ui_header_bg: #131523;

$grey: #e8eef3;

$light_grey: #f1f1f3;

$dark_grey: #171f29;

$darkest_grey: #4d4f5c;

$dark_text: #28313c;

$light_text: #99A5B5;

$blue: #1d82f5;

$red: #D30000;

$yellow: #FCBD01;

$light_green: #39e500;

$dark_green: #2CB100;

$additional_grey: #F2F4F7;

$pink: #ea4c89;

$amt_grey: #e7e9eb;
